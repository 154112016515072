@import "./colors";
@import "./base";

/**
* Custom Styles for multiple pages
*/

input:focus::placeholder {
  color: transparent;
}

// Use placeholder mixin (the size parameter is optional)
[placeholder] {
  @include placeholder(#575757, 15px);
}

/**============================================= END  ==========================================*/
.page {
  height: 100vh;
  width: 100%;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #094b70;
  // background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.top-sidebar {
  display: none;
}
.main-section {
  margin-top: 30px;
  padding-left: 0;
}

.forget-password-success-img {
  margin-left: 40%;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 0;

  .header-links {
    margin-top: auto;
    margin-bottom: -5px;
  }

  li {
    list-style-type: none;
    color: $navy;

    .logo {
      width: 166px;
      height: auto;
    }
    p {
      &:hover {
        text-decoration: underline;
      }
    }
    a {
      color: $navy;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

#test a {
  font-weight: 500;
}

.register-steps {
  ul {
    display: flex;
    justify-content: space-between;
    float: right;

    li {
      list-style-type: none;
      margin-right: 20px;
    }

    li::before {
      content: "";
      position: absolute;
      top: 87px;
      //   left: -4em;
      width: 2.7em;
      height: 2px;
      background: $orange;
      z-index: -1;
    }

    li:last-child::before {
      display: none;
    }
  }

  .numberCircle {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 1px;
    background: white;
    border: 1px solid $orange;
    color: $navy;
    text-align: center;
  }

  .numberCircle-active {
    background: $orange;
    border: 1px solid $orange;
    color: white;
  }
}

.err-msg {
  color: red;
  font-size: 13px;
  margin-left: 10px;
}

.red-label {
  border-color: red !important;
}

.login-form {
  padding-top: 50px;

  .title {
    font-weight: 600;
  }

  p {
    color: $grey;
  }

  .form-data {
    margin-top: 70px;
    // height: 60vh;
    // margin-bottom: 45%;
  }

  .form-group {
    width: 90%;
    margin-bottom: 30px;

    .input-options {
      display: flex;
      justify-content: space-between;

      label {
        color: $navy;
      }

      a {
        color: $grey;
      }
    }

    input {
      border-color: rgba(186, 186, 186, 0.2);
      border-radius: 13px;
      box-shadow: 2px 2px rgba(186, 186, 186, 0.3);
      color: black;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .body-copy {
    color: $offBlack;
  }

  .group-right {
    margin-left: auto;
  }

  .form-check {
    margin-top: 20px;

    input[type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
    }
  }

  .form-control {
    color: #727272;
    font-size: 15px;
    font-weight: 100;
  }

  .auth-seprator {
    // display: block;
    display: none;
    height: 1px;
    border: 0;
    border-top: 0.75px solid $navy;
    margin: 1em 0;
    padding: 0;
  }

  .auth-login {
    float: right;
    width: 250px;
    border-radius: 15px;
    font-size: 15px;
  }
}

// .row-login {
//   margin-bottom: 150px;
// }

// .row-register {
//   margin-bottom: 30px;
// }

.password-requirements {
  color: $grey;
  width: 90%;
  margin-left: 10px;
  margin-top: 10px;
}
.row-register-2 {
  margin-bottom: 100px;
}

.row-register-3 {
  margin-bottom: 250px;
}

.btn-orange-border {
  color: orange;
  background-color: white;
  border-color: $orange;
  width: 250px;
  border-radius: 15px;
  font-size: 15px;
  &:hover {
    background-color: $orange;
    color: white;
  }
}
.btn-orange {
  width: 250px;
  border-radius: 15px;
  font-size: 15px;
}
.btn-back {
  position: absolute;
  left: 3.5%;
  bottom: 10%;
}
.btn-bottom {
  position: absolute;
  right: 0%;
  bottom: 10%;
}

// LAND SCAPE
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page {
    margin: 0;
  }
  .left-sidebar {
    display: none !important;
  }
  .auth-seprator {
    display: none;
    margin-bottom: 40px !important;
  }
  .top-sidebar {
    background-color: #094b70;
    display: block;
    height: 150px;
  }
  .main-section {
    margin-left: 60px;
    .header-menu {
    }
  }
  .steps {
    padding-bottom: 30px;
  }

  .login-form {
    .form-data {
      .row-login {
        margin-bottom: 300px !important;
      }

      .row-register {
        margin-bottom: 130px !important;
      }
    }
  }
}

// Ipad pro portratit
