@import "./colors";
@import "./base";

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75); /* Black background with opacity */
  z-index: 300; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  .title {
    position: absolute;
    top: 4%;
    left: 45%;
    h1 {
      color: white;
    }
  }
  .close {
    position: absolute;
    top: 1%;
    left: 97%;
  }

  .overlay-gif {
    display: none;
  }
  .steps {
    // border: 3px solid red;
    position: absolute;
    margin: auto;
    height: 20%;
    width: 50%;
    bottom: 5%;
    left: 25%;

    .right-arrow {
      position: absolute;
      color: white;
      font-size: 40px;
      bottom: 20%;
      left: 77%; // 25 + 50(width of ul) + 5(padding)
    }
    .left-arrow {
      position: absolute;
      color: white;
      font-size: 40px;
      bottom: 20%;
      left: 18%; // 20(padding)
    }

    ul {
      // border: 3px solid green;
      margin: auto;
      position: absolute;
      bottom: 25%;
      left: 25%;
      width: 50%;
      display: flex;
      justify-content: space-around;
      padding: 0;
      li {
        list-style-type: none;
        // margin-right: 20px;
      }
    }
    .numberCircle {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 4px;
      background: white;
      border: 1px solid white;
      color: $navy;
      text-align: center;
    }

    .numberCircle-active {
      background: $orange;
      border: 1px solid $orange;
      color: white;
    }
  }

  .overlay-step-1 {
    .image {
      position: absolute;
      right: 10%;
      top: 20%;
      .image-text {
        color: $grey;
        position: absolute;
        right: 50%;
        top: 20%;
      }
      .fa-plus {
        color: $navy;
        margin-left: 10px;
      }
    }
    .info {
      position: absolute;
      right: 2%;
      top: 30%;
      .fa-arrow-up {
        color: $orange;
        font-size: 25px;
        margin-bottom: 5%;
      }
      p {
        color: white;
        width: 80%;
      }
    }
  }

  .overlay-step-2 {
    .image {
      position: absolute;
      left: 20%;
      top: 20%;
      .rectangle {
        width: 60%;
        max-height: 100%;
      }
      .ellipse {
        position: absolute;
        right: 70%;
        top: 50%;
      }
    }
    .info {
      position: absolute;
      right: 10%;
      top: 40%;
      .fa-arrow-left {
        color: $orange;
        font-size: 25px;
        padding-right: 20px;
      }
      .fa-arrow-top {
        display: none;
      }
      p {
        color: white;
        float: right;
      }
    }
  }

  .overlay-step-3 {
    .liver-image {
      position: absolute;
      right: 10%;
      top: 20%;
      .image-liver {
        color: $grey;
        position: absolute;
        right: 70%;
        top: 10%;
      }
      .image-minus {
        color: $orange;
        position: absolute;
        right: 5%;
        font-size: 40px;
        top: -70%;
      }
    }
    .saveRoi-image {
      position: absolute;
      right: 10%;
      top: 50%;
      .text-1 {
        color: $orange;
        position: absolute;
        left: 35%;
        top: 20%;
      }
    }
    .info {
      position: absolute;
      left: 78%;
      top: 57%;
      .fa-arrow-down {
        display: none;
      }

      .fa-arrow-up {
        color: $orange;
        font-size: 25px;
        margin-bottom: 5%;
      }
      p {
        color: white;
        width: 80%;
      }
    }
  }

  .overlay-step-4 {
    .liver-image {
      position: absolute;
      right: 10%;
      top: 20%;
      .image-liver {
        color: $grey;
        position: absolute;
        right: 70%;
        top: 10%;
      }
      .image-minus {
        color: $orange;
        position: absolute;
        right: 5%;
        font-size: 40px;
        top: -70%;
      }
    }
    .saveRoi-image {
      position: absolute;
      right: 10%;
      top: 50%;
      .text-1 {
        color: $orange;
        position: absolute;
        left: 35%;
        top: 20%;
      }
    }
    .info {
      position: absolute;
      left: 78%;
      top: 57%;
      .fa-arrow-down {
        display: none;
      }

      .fa-arrow-up {
        color: $orange;
        font-size: 25px;
        margin-bottom: 5%;
      }
      p {
        color: white;
        width: 80%;
      }
    }
  }
  .overlay-step-5 {
    .image {
      position: absolute;
      left: 10%;
      .image-text {
        color: $navy;
        position: absolute;
        right: 20%;
        top: 20%;
      }
    }
    .info {
      position: absolute;
      left: 10%;
      top: 10%;
      .fa-arrow-down {
        display: none;
      }
      .fa-arrow-up {
        color: $orange;
        font-size: 25px;
        margin-bottom: 3%;
      }
      p {
        color: white;
        width: 60%;
      }
    }
  }

  .overlay-step-6 {
    .image {
      position: absolute;
      left: 10%;
      .image-text {
        color: $navy;
        position: absolute;
        right: 20%;
        top: 20%;
      }
    }
    .info {
      position: absolute;
      left: 10%;
      top: 10%;
      .fa-arrow-down {
        display: none;
      }
      .fa-arrow-up {
        color: $orange;
        font-size: 25px;
        margin-bottom: 3%;
      }
      p {
        color: white;
        width: 60%;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .info {
    background-color: $orange;
    padding: 15px;
    border-radius: 20px;
  }
  #overlay {
    height: 93%;
    top: 8.7%;
    .title {
      display: none;
    }
    .steps {
      bottom: 40%;
      height: 30% !important;
      // right: 5%;
      width: 10%;
      left: 90%;
      ul {
        display: block;
        li {
          margin: 20px;
        }
      }
      .right-arrow {
        bottom: 6% !important;
        display: none;
      }
      .left-arrow {
        bottom: 6% !important;
        left: 13%;
        display: none;
      }

      .arrow-down {
        color: $orange;
        font-size: 30px;
        position: absolute;
        bottom: 10%;
        right: 25%;
      }
      .arrow-up {
        color: $orange;
        font-size: 30px;
        position: absolute;
        bottom: 120%;
        right: 25%;
      }
    }

    .overlay-gif {
      display: block;
      position: absolute;
      margin: auto;
      height: 100%;
      width: 100%;
      top: 8%;
      bottom: 0%;
      left: 5%;
      img {
        width: 87%;
        // height: 100%;
      }
    }

    .overlay-steps {
      display: none;
      .overlay-step-1 {
        .info {
          position: absolute;
          right: 2%;
          top: 65% !important;
          width: 40% !important;
          p {
            width: 100%;
            padding: 5px;
            margin: 0px;
          }
          .fa-arrow-up {
            display: none;
          }
        }
        .info:after {
          top: 95%;
          left: 11%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          border-top-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }

      .overlay-step-2 {
        .image {
          left: 6%;
          top: 20%;
          .rectangle {
            width: 95%;
          }
          .ellipse {
            right: 50%;
            top: 70%;
          }
        }
        .info {
          right: 20% !important;
          top: 68% !important;
          width: 40% !important;
          p {
            width: 100%;
            padding: 5px;
            margin: 0px;
          }
          .fa-arrow-left {
            display: none !important;
          }
          .fa-arrow-up {
            display: none;
            color: #f0a140;
            font-size: 25px;
            margin-bottom: 5%;
          }
        }
        .info:after {
          bottom: 100%;
          left: 11%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          border-bottom-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }

      .overlay-step-3 {
        .info {
          right: 25% !important;
          top: 65% !important;
          width: 40%;
          p {
            width: 100%;
            padding: 5px;
            margin: 0px;
          }
        }
        .fa-arrow-up {
          display: none !important;
        }
        .fa-arrow-down {
          display: none !important;
          color: $orange;
          font-size: 25px;
          margin-bottom: 5%;
        }
        .info:after {
          top: 95%;
          left: 11%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          border-top-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }

      .overlay-step-4 {
        .info {
          right: 25% !important;
          top: 65% !important;
          width: 40%;
          p {
            width: 100%;
            padding: 5px;
            margin: 0px;
          }
        }
        .fa-arrow-up {
          display: none !important;
        }
        .fa-arrow-down {
          display: none !important;
          color: $orange;
          font-size: 25px;
          margin-bottom: 5%;
        }
        .info:after {
          top: 95%;
          left: 11%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          border-top-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }

      .overlay-step-5 {
        .info {
          position: absolute;
          left: 50% !important;
          top: 65% !important;
          width: 40%;
          p {
            width: 100%;
            padding: 5px;
            margin: 0px;
          }
        }
        .fa-arrow-up {
          display: none !important;
        }
        .fa-arrow-down {
          display: none !important;
          color: $orange;
          font-size: 25px;
          margin-bottom: 5%;
        }
        .info:after {
          top: 95%;
          left: 11%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          border-top-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }

      .overlay-step-6 {
        .info {
          position: absolute;
          left: 10%;
          top: 15% !important;
          width: 40%;
          p {
            width: 100%;
            padding: 5px;
            margin: 0px;
          }
          .fa-arrow-up {
            color: $orange;
            font-size: 25px;
            margin-bottom: 3%;
            display: none;
          }
        }
        .info:after {
          bottom: 95%;
          left: 11%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          border-bottom-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }
    }
  }
  #pause-element {
    width: 95% !important;
  }
}
