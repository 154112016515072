@import "./colors";
@import "./base";

.tablet-sidebar {
  display: none;
}

.exam {
  display: flex;
  align-items: stretch;
  overflow-x: hidden; //horizontal

  .customSelect__control {
    border-radius: 15px !important;
    width: 86% !important;
  }

  .page {
    height: auto !important;
  }

  .top-bar {
    padding-top: 20px;
    margin-bottom: 30px;

    .timer {
      margin-left: 20px;
    }

    .info {
      display: inline;

      .pause {
        float: left;
        color: red;

        &:hover {
          cursor: pointer;
        }
      }

      .exam-number {
        float: right;
      }
    }

    .fa-info-circle {
      color: $navy;
      font-size: 25px;

      &:hover {
        cursor: pointer;
      }
    }

    .right-info {
      float: right;
      margin-right: 32%;

      .counter {
        display: inline;
        color: $navy;
        font-size: 22px;
        font-weight: bold;
        margin-right: 20px;
      }

      .overlay-actions {
        display: inline;

        .overlay-icon {
          display: inline;
        }

        .overlay-info-box {
          opacity: 1;
          border-top-left-radius: 13px;
          border-bottom-left-radius: 13px;
          border-bottom-right-radius: 13px;
          // display: none;
          position: absolute;
          background-color: $orange;
          color: white;
          top: 140%;
          right: 30%;
          width: 100%;

          .content {
            padding: 10px;
            margin-bottom: 0px;

            .title {
              // margin-bottom: 1em;
              font-weight: bold;
            }

            p {
              margin-bottom: 0px;
            }
          }
        }

        .overlay-info-box:after {
          bottom: 100%;
          right: 0%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $orange;
          border-width: 20px;
          margin-left: -20px;
        }
      }
    }
  }

  .exam {
    padding-bottom: 20%;

    .header {
      font-weight: 500;
    }

    .question-description {
      p {
        margin-bottom: 0px !important;
      }

      margin-bottom: 1.5em;
    }

    .pd-info {
      color: $navy;
      margin-top: 1em;
      font-size: 16px;

      span {
        font-weight: bold;
      }
    }

    .canvas {
      position: relative;

      .undo-roi {
        cursor: pointer;
        position: absolute;
        color: red;
        right: 2%;
        top: 2%;
        width: 35px;
        z-index: 9999;
      }

      .undo-text {
        position: absolute;
        color: $orange;
        right: 2%;
        top: 8%;
        width: 35px;
        z-index: 9999;
      }
    }

    .liver-img {
      max-width: 1000px;
      margin-bottom: 50px;
    }

    .sidebar-actions {
      margin-top: 110px;
      position: relative;

      .form-group {
        position: relative;
        margin-bottom: 25px;
        padding-left: 0px;

        .tissue-list {
          -moz-appearance: none;
          /* Firefox */
          -webkit-appearance: none;
          /* Safari and Chrome */
          appearance: none;
        }

        select {
          border-color: rgba(186, 186, 186, 0.2);
          border-radius: 13px;
          box-shadow: 2px 2px rgba(186, 186, 186, 0.3);
        }

        .fa-caret-down {
          position: absolute;

          color: $navy;
          right: 36%;
          bottom: 23%;
          font-size: 20px;

          &:hover {
            cursor: pointer;
          }
        }

        .fa-plus {
          position: absolute;
          bottom: 10px;
          color: $navy;
          right: 50px;

          &:hover {
            cursor: pointer;
          }
        }

        .form-control {
          border-radius: 15px;
          width: 70%;
        }

        .add-new {
          color: $grey;
          font-size: 12px;
          float: right;
          margin-top: 10px;
        }
      }

      .tissues-list {
        ul {
          padding-left: 0px;
          width: 70%;
        }

        li {
          list-style-type: none;
          color: $grey;
          display: flex;
          justify-content: space-between;
          width: 115%;

          p {
            display: inline;
            margin: 10px;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .delete-tissue {
          color: red;
          font-size: 10px;
          // margin-left: 150px;
          padding-top: 5px;
          margin: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      #save-roi-btn-overlay {
        display: none;
      }

      #complete-image-btn-overlay {
        display: none;
      }

      .btn-orange {
        width: 70%;
        border-radius: 15px;
      }
      .btn_transparent {
        cursor: pointer;
        border: 0px solid #00ffffff;
        background-color: transparent;
        color: red;
        font-size: 12px;
    }

      .next-btn {
        margin-top: 30px;
        width: 83%;
      }

      .save-roi-btn {
        margin-top: 30px;
        width: 83%;
        color: $orange;
        border-color: $orange;
        background-color: white;

        &:hover {
          color: white;
          background-color: $navy;
          border-color: $navy;
        }
      }

      .fa-arrow-right {
        position: absolute;
        bottom: 10px;
        right: 90px;
        color: white;
        font-size: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

/**
* Exam Result custom CSS
*/
.exam-result {
  .exam {
    .time-info {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      width: 90%;
      padding-left: 0;
      color: $navy;
      margin-bottom: 40px;
    }
  }

  .fa-caret-left {
    cursor: pointer;
  }

  .fa-caret-right {
    cursor: pointer;
  }

  .err-msg {
    color: red;
    font-size: 13px;
    margin-left: 10px;
  }

  .red-label {
    border-color: red !important;
  }
}

// LAND SCAPE
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {}

// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .exam {
    margin: 0;
    overflow-x: hidden !important; //horizontal
  }

  .hide-on-mobile {
    display: none;
  }

  .exam {
    overflow-x: visible; // this to make the overlay works
    display: block;

    .tissues-list {
      height: 30%;
      overflow-y: scroll;
    }

    .top-bar {
      .fa-info-circle {
        padding-left: 20px;
      }

      #pause-element {
        margin-left: 4%;
      }

      .right-info {
        margin-right: 0% !important;
        display: flex !important;
        float: none !important;
        justify-content: space-between !important;

        .overlay-actions {
          .overlay-info-box {
            top: -10%;
            right: 15%;
            width: 70%;
          }
        }

        .overlay-info-box {
          border-top-right-radius: 13px;
        }

        .overlay-info-box:after {
          bottom: 100% !important;
          right: 0% !important;
          //
          left: 99% !important;
          top: 10% !important;
          border: solid transparent !important;
          content: " " !important;
          height: 0 !important;
          width: 0 !important;
          position: absolute !important;
          pointer-events: none !important;
          border-color: rgba(136, 183, 213, 0) !important;
          border-left-color: $orange !important;
          border-width: 10px !important;
          margin-left: 0px !important;
        }
      }
    }

    .question-description {
      width: 90%;
    }

    .undo-roi {
      right: 15% !important;
    }

    .undo-text {
      right: 15% !important;
      top: 11% !important;
    }

    .question-description {
      p {
        margin-bottom: 0px !important;
        display: inline;
      }

      margin-bottom: 1.5em;
    }

    .sidebar-actions {
      margin-top: 60px !important;

      .form-group {
        padding-left: 0px;
      }

      .save-roi-btn {
        margin-top: 0px !important;
      }
    }

    .answer-tablet {
      margin-left: 7%;
    }

    #overlay {
      .close {
        left: 93% !important;
      }
    }
  }

  // Answer
}