@import "./colors";
@import "./base";

.tablet-sidebar {
  display: none;
}

body {
  height: 100%;
  font-family: "Montserrat";
}

.ant-progress-circle-path {
  stroke: $orange !important;
}

.dashboard {
  display: flex;
  align-items: stretch;
  overflow-x: hidden; //horizontal
  .content {
    height: 100vh;
    width: 95%;
    margin-left: 30px;
  }

  .page-title {
    padding-top: 4%;
    // margin-bottom: 30px;
    .header {
      font-weight: 600;
      font-size: 2em;
    }
  }

  .dr-info-table {
    background-color: $navy;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 20px;
    .status-info {
      padding: 10px;
      h2 {
        color: white;
        margin-bottom: 20px;
        font-size: 1.7em;
        text-transform: uppercase;
        font-weight: 400;
      }
      p {
        color: white;
        font-size: 1.3em;
        margin-bottom: 70px;
      }

      button {
        width: 60%;
        margin-right: 20%;
        margin-left: 20%;
        border-radius: 15px;
        margin-bottom: 30px;
        &:hover {
          background-color: white !important;
          color: $orange;
          border-color: $orange !important;
        }
      }
    }

    .progress {
      margin: auto;
      width: 100%;
      padding: 10px;
      height: 100%;
      background-color: transparent;
      .ant-progress {
        margin: auto;
      }
      .ant-progress-inner {
        width: 170px !important;
        height: 170px !important;
      }
      .ant-progress-text {
        font-size: 26px;
        color: white;
      }
    }
    .time {
      margin: auto;
      width: 90%;
      // border: 3px solid green;
      padding: 10px;
      h4 {
        font-size: 1.5em;
        color: white;
        text-align: center;
      }
      .estimated-time {
        font-size: 5em;
        color: white;
        text-align: center;
        margin-bottom: 0;
      }
      p {
        font-size: 1.5em;
        color: white;
        text-align: center;
      }
    }
  }

  .stats {
    margin-top: 2%;
    .title {
      color: $navy;
      font-weight: 600;
      font-size: 1.7em;
      margin-bottom: 20px;
      display: inline-block;
    }
    a {
      margin-left: 20px;
      color: $navy;
      text-decoration: underline;
      &:hover {
      }
    }
    // background-color: red;
  }

  .stats-box {
    border-radius: 15px;
    box-shadow: 3px 3px 5px 2px #ccc;
    margin-bottom: 30px;
    p {
      text-transform: uppercase;

      font-weight: 500;
      color: $navy;
      font-size: 1.2em;
      margin-left: 5%;
      padding-top: 5%;
    }
    span {
      text-align: center;
      display: block;
      font-size: 5em;
      color: $grey;
    }
    button {
      padding: 6px;
      margin-left: 33%;
      width: 30%;
      border-radius: 15px;
      font-size: 1.2em;
      line-height: 1;
      margin-bottom: 5%;
      border-bottom-color: white !important;
      border-width: 0px;
    }
  }

  .progress-box {
    border-radius: 15px;
    box-shadow: 3px 3px 5px 2px #ccc;
    margin-bottom: 30px;
    p {
      font-weight: 500;
      color: $navy;
      font-size: 1.2em;
      margin-left: 7%;
      padding-top: 5%;
      text-transform: uppercase;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    span {
      margin-left: 7%;
      display: block;
      color: $grey;
    }
    .progress {
      margin-left: 10%;
      margin-top: 5%;
      margin-bottom: 5%;
      padding-bottom: 10%;
      height: 100%;
      background-color: transparent;
      .ant-progress-inner {
        width: 170px !important;
        height: 170px !important;
      }
      .ant-progress-text {
        font-size: 28px;
        // color: white;
      }
      .ant-progress-circle-path {
        stroke: orange !important;
      }
    }

    .usability {
      margin-top: 30%;
      .indicator {
        .circle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px;

          float: left;
          margin-right: 20px;
          margin-bottom: 40px;
        }
        .orange {
          background-color: $orange;
        }
        .grey {
          background-color: $grey;
        }
      }
    }

    button {
      padding: 6px;
      margin-left: 33%;
      width: 30%;
      border-radius: 15px;
      font-size: 1.2em;
      line-height: 1;
      margin-bottom: 5%;
    }
  }

  .bar-chart-box {
    border-radius: 15px;
    box-shadow: 3px 3px 5px 2px #ccc;
    margin-bottom: 30px;
    p {
      text-transform: uppercase;
      font-weight: 500;
      color: $navy;
      font-size: 1.2em;
      margin-left: 4%;
      padding-top: 5%;
    }
    span {
      margin-left: 7%;
      display: block;
      font-size: 3em;
      color: $grey;
    }
    button {
      padding: 6px;
      margin-left: 33%;
      width: 30%;
      border-radius: 15px;
      font-size: 1.2em;
      line-height: 1;
      margin-bottom: 5%;
    }
  }

  .btn-orange {
    border-bottom-color: white !important;
    border-width: 0px;
    &:hover {
      background-color: $navy;
      color: white;
    }
  }

  .btn-green {
    border-radius: 15px;
    font-size: 15px;
    line-height: 1;
  }

  .btn-extra-info {
    float: left;
    margin-bottom: 15px;
    width: 60%;
    margin-left: 60px;
  }

  .btn-review {
    float: left;
    margin-bottom: 15px;
    width: 100%;
    // margin-left: 60px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mini-sidebar + .content {
    margin-left: 0% !important;
  }
  .sidebar + .content {
    margin-left: 0% !important;
  }
  .dashboard {
    overflow-x: hidden !important; //horizontal
    display: block;
    // align-items: stretch;
    .content {
      width: 100% !important;
    }
  }
  .dr-dashboard {
    margin: 0;
  }

  .dr-dashboard {
    display: block;
    .top-bar {
      .fa-info-circle {
        padding-left: 20px;
      }
    }
  }
}
