@import "../../colors";
@import "../../base";

.tablet-sidebar {
  display: none;
}
body {
  height: 100%;
  font-family: "Montserrat";
}

// Use placeholder mixin (the size parameter is optional)

.profile {
  display: flex;
  align-items: stretch;

  .auth-seprator {
    display: block;
    height: 1px;
    border: 0;
    border-top: 0.75px solid $navy;
    // margin: 2em 0;
    margin-top: 5em;
    margin-bottom: 3em;

    padding: 0;
    width: 100%;
  }

  .top-bar {
    padding-top: 30px;
    margin-bottom: 50px;
    a {
      color: $navy;
      // font-weight: 300;
    }
    .edit-profile {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .profile-sections-list {
    width: 90%;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    margin-top: 20px;
    font-size: 18px;
    .active {
      color: $orange;
    }
    li {
      cursor: pointer;
    }
  }
  .doctors {
    .page-title {
      margin-bottom: 30px;
    }

    .header {
      // display: inline;
      h1 {
        font-weight: bold;
        margin-bottom: 20px;
        display: contents;
      }
      span {
        padding-left: 10px;
        color: $navy;
        font-weight: 500;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    table {
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      border-collapse: collapse;
      width: 100%;
      font-size: 15px;
      color: #727272;
      td,
      th {
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
      }

      .greyBG {
        background-color: $lightGrey;
      }
      tr#t01 {
        width: 100%;
        font-weight: 500;
        background-color: $navy;
        color: white;
        font-size: 14px;
        padding: 8px;
      }

      .button {
        font-weight: 300;
        background-color: $green; /* Green */
        border: none;
        border-radius: 24px;
        color: white;
        padding: 5px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
      }

      .namelink {
        color: #297eb2;
      }

      .plus {
        color: #174b70;
        font-weight: 700;
        font-size: 18px;
      }

      .plusheader {
        font-weight: 700;
        font-size: 18px;
      }
    }

    .user-info {
      .form-data {
        // margin-top: 70px;
      }

      .form-group {
        width: 90%;
        margin-bottom: 40px;
        .input-options {
          display: flex;
          justify-content: space-between;

          h2 {
            color: $navy;
            font-weight: 700;
          }
        }

        input {
          color: #575757;
          font-weight: 400;
          font-size: 15px;
          border-color: rgba(186, 186, 186, 0.2);
          border-radius: 13px;
          box-shadow: 2px 2px rgba(186, 186, 186, 0.3);
        }

        .view-input {
          border: 0;
          outline: 0;
          border-radius: 0px;
          background: transparent;
          box-shadow: 0px 1px rgba(186, 186, 186, 0.3);
          border-bottom: 1px solid $lightGrey;
        }
      }

      .group-right {
        margin-left: auto;
      }

      .form-control {
        color: #727272;
        font-size: 15px;
        font-weight: 100;
      }
    }
  }

  .btn-orange {
    width: 20%;
    border-radius: 15px;
    float: right;
  }
  .btn-green {
    border-radius: 15px;
    font-size: 15px;
    line-height: 1;
  }

  .btn-extra-info {
    margin-bottom: 15px;
    width: 50%;
    margin-left: 60px;
  }
}

// LAND SCAPE
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .exam-page {
    margin: 0;
  }

  .profile {
    display: block;
    .auth-seprator {
      margin-top: 20%;
      margin-bottom: 6%;
      width: 100%;
    }
    .btn-orange {
      width: 30%;
      margin-left: 70%;
    }
    .top-bar {
      .fa-info-circle {
        padding-left: 20px;
      }
    }
  }
}
