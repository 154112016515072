@import "./colors";
@import "./base";

.svg-image {
  color: white;
}
.menu-icon {
  margin-right: 10px;
}

.mini-sidebar + .page {
  margin-left: 9%;
}

.sidebar + .page {
  margin-left: 16%;
}

.mini-sidebar + .content {
  margin-left: 9% !important;
}

.sidebar + .content {
  margin-left: 16% !important;
}

.mini-sidebar {
  flex: none;
  float: left;
  width: 7%;
  box-shadow: 3px 3px 5px 6px #ccc;
  height: 100%;
  position: fixed;
  z-index: 100;

  .side-logo {
    max-width: 40px;
    height: auto;
  }
  .cheveron-circle-left {
    position: absolute;
    left: 92%;
    top: 6%;
    cursor: pointer;
  }
  .fa-th-large {
    color: $navy;
  }
  .change-view-menu {
    position: absolute;
    left: 100%;
    top: 11.4%;
    z-index: 100;
    ul {
      background-color: white;
      box-shadow: 2px 2px 2px 2px #ccc;
      li {
        cursor: pointer;

        list-style-type: none;
        // padding-top: 20px;
        // padding-bottom: 15px;
        padding: 20px;
        font-size: 15px;
        text-align: center;
        &:hover {
          background-color: #dfebfd;
        }
      }
    }
  }

  ul {
    padding-left: 0;
    margin-top: 30px;
    .name {
      font-weight: bold;
      color: $navy;
      margin-top: 12px;
    }
    .change-view {
      cursor: pointer;
      font-size: 15px;
    }
    li {
      list-style-type: none;
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 20px;
      text-align: center;
      hr {
        width: 70%;
        // border: 0.5px $grey;
        color: $grey;
      }
    }

    .sidebar-item {
      &:hover {
        background-color: $grey;
      }
    }
    .active {
      background-color: $navy;

      i {
        color: white;
      }
    }
    .logout {
      position: absolute;
      bottom: 5%;
      left: 23%;
      font-size: 15px;
      color: $navy;
      font-weight: bold;
    }
  }

  .fa-align-justify {
    cursor: pointer;
    color: $navy;
  }

  .fa-user {
    cursor: pointer;
    color: $navy;
  }

  .fa-power-off {
    cursor: pointer;
    transform: rotate(270deg);
    color: $navy;
  }
}

.sidebar {
  float: left;
  width: 15%;
  box-shadow: 3px 3px 5px 6px #ccc;
  // height: 110vh;
  height: 100%;
  position: fixed;

  .cheveron-circle-left {
    position: absolute;
    // left: 12.5%;
    right: -5%;
    top: 7%;
    cursor: pointer;
  }
  .side-logo {
    max-width: 140px;
    height: auto;
  }
  ul {
    padding-left: 0;
    margin-top: 30px;
    height: 100%;
    .text-center {
      padding-left: 0px;
    }

    .text-right {
      padding-right: 0;
      padding-left: 0px;
      margin-right: 20px;
    }

    .name {
      font-weight: bold;
      color: $navy;
      margin-top: 12px;
    }
    li {
      list-style-type: none;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      font-size: 15px;
      color: $navy;
      hr {
        width: 80%;
        // border: 0.5px $grey;
        color: $grey;
      }
    }

    .logout {
      position: absolute;
      bottom: 5%;
      left: 36%;
    }

    .active {
      background-color: $navy;
      color: white;
      font-size: 15px;
    }
  }
}

// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .left-sidebar {
    display: none !important;
  }

  .tablet-sidebar {
    display: block !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    position: relative;
    ul {
      width: 90%;
      padding-left: 0;
      display: flex;
      justify-content: space-around;
      margin-left: 6%;
      .name {
        font-weight: bold;
        color: $navy;
      }
      .side-logo {
        max-width: 40px !important;
        height: auto !important;
      }

      .sidebar-item {
        &:hover {
          background-color: $grey;
        }
      }
      hr {
        border-left: 1px solid $grey;
        height: 50px;
        margin-right: 20px;
        margin-left: 3px;
      }
      li {
        list-style-type: none;
        padding: 25px;
        font-size: 20px;
        text-align: center;
        display: flex;
        .svg-image {
          width: 25px;
        }

        .fa-th-large {
          width: 25px;
          color: $navy;
        }
      }

      .active {
        background-color: $navy;
        i {
          color: white;
        }
      }
      .logout {
        // position: relative;
        // top: 0.8%;
        // right: 5%;
        // left: 60%;
        margin-left: auto;
        font-size: 13px;
        color: $navy;
        font-weight: bold;
      }
    }
    .change-view-menu {
      // position: absolute;
      // left: 15%;
      // top: 8%;
      // z-index: 100;
      // width: 15%;
      position: absolute;
      left: 20%;
      top: 100%;
      z-index: 100;
      width: 15.4%;

      ul {
        background-color: white;
        box-shadow: 1px 1px 1px 1px #ccc;
        display: block;
        width: 100%;
        .change-view {
          cursor: pointer;
        }
        li {
          cursor: pointer;
          list-style-type: none;
          // padding-top: 20px;
          // padding-bottom: 15px;
          padding: 20px;
          font-size: 15px;
          text-align: center;
          &:hover {
            background-color: #dfebfd;
          }
        }
      }
    }

    p {
      position: absolute;
      right: 7%;
      top: 2%;
    }

    .fa-align-justify {
      cursor: pointer;
      color: $navy;
    }

    .fa-user {
      cursor: pointer;
      color: $navy;
    }

    .fa-power-off {
      cursor: pointer;
      transform: rotate(270deg);
      color: $navy;
    }
  }
}
