* {
  font-family: "Montserrat";
}

body {
  height: 100%;
}

.customSelect__control {
  border-color: rgba(186, 186, 186, 0.2) !important;
  border-radius: 13px !important;
  box-shadow: 2px 2px rgba(186, 186, 186, 0.3) !important;
}

.customSelect__input {
  input {
    width: 100px !important;
    border-color: red !important;
    box-shadow: 0px 0px rgba(186, 186, 186, 0.3) !important;
  }
}

.read-only-input {
  pointer-events: none;
}

.customSelect__dropdown-indicator {
  color: $navy !important;
}

h1 {
  color: $navy;
  font-size: 24px;
}

h2 {
  color: $navy;
  font-size: 16px;
}

h3 {
  color: $navy;
  font-size: 15px;
}

h4 {
  color: $grey;
  font-size: 13px;
  font-weight: bold;
}

h5 {
  columns: $grey;
  font-size: 14px;
}

h6 {
  columns: $grey;
  font-size: 13px;
}

.btn-orange {
  background-color: $orange;
  color: white;

  &:hover {
    color: white;
    background-color: $navy;
  }
}

.btn-green {
  background-color: $green;
  color: white;

  &:hover {
    color: white;
    background-color: $navy;
  }
}

.bold {
  font-weight: bold;
}

.hide-this {
  display: none !important;
}

.logout {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.no-style {
  text-decoration: inherit;
  color: inherit;
  &:hover {
    text-decoration: inherit;
    color: inherit;
  }
}

@mixin placeholder($color, $size: "") {
  &::-webkit-input-placeholder {
    color: $color;
    font-weight: 400;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

.no-scroll {
  overflow-y: hidden; // hide vertical
  position: fixed;
}

.hide-on-desktop {
  display: none;
}
// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .hide-on-mobile {
    display: none;
  }

  .hide-on-desktop {
    display: block;
  }
}
