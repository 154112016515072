@import "../../colors";
@import "../../base";

body {
  height: 100%;
  font-family: "Montserrat";
}

.doctors-list-page {
  display: flex;
  align-items: stretch;
  .top-bar {
    padding-top: 20px;
    margin-bottom: 30px;

    .info {
      display: inline;

      .pause {
        float: left;
        color: red;

        &:hover {
          cursor: pointer;
        }
      }

      .exam-number {
        float: right;
      }
    }

    .fa-info-circle {
      color: $navy;
      font-size: 25px;

      &:hover {
        cursor: pointer;
      }
    }

    .counter {
      color: $navy;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .doctors {
    .page-title {
      margin-bottom: 30px;
    }

    .table-action {
      margin-bottom: 30px;
    }

    .table-footer {
      margin-top: 30px;
      .pagination {
        display: inline-flex;
        float: right;
      }
    }

    .header {
      font-weight: 500;
    }

    .table-overflow {
      overflow-x: auto;
    }
    table {
      font-family: "Montserrat", sans-serif;
      // font-weight: 300;
      border-collapse: collapse;
      width: 100%;
      font-size: 15px;
      color: #727272;
      td {
        text-align: left;
        padding: 15px;
        min-width: 160px;
      }
      th {
        text-transform: uppercase;
        text-align: left;
        padding: 15px;
      }

      // .extra-info {
      //   background-color: white !important;
      // }
      // tr:nth-child(odd) {
      //   background-color: $lightGrey;
      // }

      .left-align {
        text-align: left;
      }
      .greyBG {
        background-color: $lightGrey;
      }
      tr#t01 {
        width: 100%;
        font-weight: 500;
        background-color: $navy;
        color: white;
        font-size: 14px;
        padding: 8px;
      }

      .button {
        font-weight: 300;
        background-color: $green; /* Green */
        border: none;
        border-radius: 24px;
        color: white;
        padding: 5px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
      }

      .namelink {
        a {
          color: #297eb2;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .plus {
        color: #174b70;
        font-weight: 700;
        font-size: 18px;
        min-width: 10px;
      }

      .plusheader {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  .tissue-list {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
  }
  label {
    margin-right: 10px;
    color: $lightBlue;
    margin-right: 10px;
    margin-top: 7px;
  }
  select {
    width: 20%;
    border-color: rgba(186, 186, 186, 0.2);
    border-radius: 13px;
    box-shadow: 2px 2px rgba(186, 186, 186, 0.3);
  }
  .fa-caret-down {
    position: absolute;

    color: $orange;
    left: 27%;
    bottom: 23%;
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .search-input {
    border-radius: 13px;
    box-shadow: 2px 2px rgba(186, 186, 186, 0.3);
    // width: 90%;
  }
  .bg-grey {
    background-color: $lightGrey;
  }

  .btn-green {
    border-radius: 15px;
    font-size: 15px;
    line-height: 1;
  }

  .btn-extra-info {
    float: left;
    margin-bottom: 15px;
    width: 60%;
    margin-left: 60px;
  }

  .btn-review {
    float: left;
    margin-bottom: 15px;
    width: 100%;
    // margin-left: 60px;
  }

  .blue-color {
    color: $lightBlue;
  }
  .fa-pen {
    font-size: 13px;
  }

  .show-with-transition {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .m-fadeIn {
    visibility: visible;
    opacity: 1;
    // transition: visibility 0s linear 0s, opacity 100ms;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  }

  .fa-sort {
    cursor: pointer;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

// Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .doctors-list-page {
    margin: 0;
    display: block;
    align-items: stretch;
    // overflow-x: scroll !important; //horizontal
  }

  .mini-sidebar + .page {
    margin-left: 0% !important;
  }

  table {
    th {
      text-transform: uppercase;
      text-align: left;
      padding: 5px !important;
      font-size: 13px !important;
    }
  }
}
