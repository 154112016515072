@import "./colors";
@import "./base";

#popup {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  .box {
    // border-color: $navy;
    border: 4px solid $navy;
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 33%;
  }

  .message {
    padding: 40px;
    padding-bottom: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: $navy;
  }
  .actions {
    text-align: center;
    padding-bottom: 30px;
    .btn-blue {
      background-color: $navy;
      color: white;
      border-radius: 15px;
      font-size: 15px;
      margin-right: 10px;
      margin-left: 10px;
      width: 30%;
      font-weight: 200;
      &:hover {
        color: white;
        background-color: $orange;
      }
    }
  }
  .check {
    margin-top: 20px;
    // padding-top: 30px;
    width: 60%;
    margin-left: 20%;
    text-align: left;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #popup {
    .box {
      top: 40%;
      left: 26%;
      transform: translate(-10%, -20%);
      max-width: 65% !important;
      max-height: 30%;
    }
  }
}
